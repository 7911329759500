const COOKIE_NAME = 'giveaway_popup';
const $giveaway = $('.js-giveaway');
const $popup = $giveaway.closest('.js-popup');
const gpID = $giveaway.attr('id');
const delay = $giveaway.data('delay') ? parseInt($giveaway.data('delay'), 10) * 1000 : 0;

const getCookie = () => {
  const parts = `; ${document.cookie}`.split(`; ${COOKIE_NAME}=`);
  return parts.length === 2 ? parts.pop().split(';').shift() : undefined;
};

const setCookie = () => {
  const expires = new Date($giveaway.data('deadline')).toGMTString();
  document.cookie = `${COOKIE_NAME}=${gpID}; path=/; expires=${expires}`;
};

const handleClosePopup = ({ target }) => {
  setCookie();
  return target.nodeName === 'A' ? $popup.addClass('open') : false;
};

export const Giveaway = () => {
  if ($giveaway.length === 0 || getCookie(COOKIE_NAME)?.includes(gpID)) return;
  setTimeout(() => $popup.addClass('open').on('click', '.js-popup-close', handleClosePopup), delay);
};
