/* eslint no-console: "off", prefer-object-spread: "off" */

import { Deserializer } from 'jsonapi-serializer';
import 'owl.carousel';
import { initTrackViews } from '../analytics/trackViews';

export const Banners = (isReact = false) => {
  const apiUrl = window.gon && window.gon.global.advert.apiUrl;
  const analyticsEnabled = window.gon && window.gon.global.advert.analyticsEnabled;
  const adsenseEnabled = window.gon && window.gon.flipflop.google_ads;

  const bannerPositions = isReact
    ? document.querySelectorAll('.js-company-react-img')
    : document.querySelectorAll('.js-company-img');

  const deserialize = async (json, options = {}) => new Promise(async (resolve, reject) => {
    try {
      const data = await new Deserializer({ keyForAttribute: 'camelCase', typeAsAttribute: true, ...options }).deserialize(json);
      return resolve(data);
    } catch (error) {
      const { message } = error;
      return reject(new Error(`Deserialize aborting. | ${message}`));
    }
  });

  const renderSingle = ({ banner_id, banner_name, slug: banner_slug, imageUrl, url, customAnalytics }, options) => {
    const trackProperties = Object.assign({
      banner_id,
      banner_name,
      banner_slug,
      banner_url: url
    }, options.trackProperties);
    const dataTrack = JSON.stringify({ event: options.clickEvent, properties: trackProperties });
    const customAnalyticsContent = customAnalytics ? `<span class="custom-analytics">${customAnalytics}</span>` : '';

    return (
      `<a href="${url}" target="_blank" data-track='${dataTrack}' data-event="${options.viewEvent}" data-properties='${JSON.stringify(trackProperties)}'>
        <img src="${imageUrl}" alt="">
        ${customAnalyticsContent}
      </a>`
    );
  };

  const renderSlider = (banners, options) => (
    banners.map(banner => renderSingle(banner, options)).join('')
  );

  const handleBanners = async (response) => {
    if (isReact) $(bannerPositions).each(function () {
      $(this).html = '';
    });

    const updated = await deserialize(response);

    updated.forEach(item => {
      const targetEl = $(`[data-slug=${item.slug}]`);
      const companyPublicId = targetEl.data('company-id');
      const options = targetEl.data();
      const banners = item.postings.map(i => ({
        banner_id: i.banner.id,
        banner_name: i.banner.name,
        slug: item.slug,
        imageUrl: i.banner.imageUrl,
        url: i.banner.url,
        customAnalytics: i.banner.customAnalytics,
        position: i.position || 999,
        companyPublicId: +i.companyPublicId
      }));

      const companyBanners = companyPublicId ? banners.filter(i => i.companyPublicId === companyPublicId) : [];
      const filteredBanners = companyBanners.length > 0 ? companyBanners : banners;

      if (filteredBanners.length) {
        targetEl.removeClass('hidden');

        if (item.layout === 'slider') {
          targetEl.html('').append(renderSlider(filteredBanners.sort((a, b) => a.position - b.position), options));
          targetEl.addClass('owl-carousel full-width').owlCarousel({
            loop: false,
            rewind: true,
            items: 1,
            autoplay: true,
            autoplayTimeout: 5000
          });
        } else {
          const banner = filteredBanners[Math.floor(Math.random() * filteredBanners.length)];

          if (targetEl.data('bg')) {
            const trackProperties = Object.assign({
              banner_id: banner.banner_id,
              banner_name: banner.banner_name,
              banner_slug: banner.slug,
              banner_url: banner.url
            }, options.trackProperties);
            const dataTrack = JSON.stringify({ event: options.clickEvent, properties: trackProperties });
            const customAnalyticsContent = banner.customAnalytics ? `<span class="custom-analytics">${banner.customAnalytics}</span>` : '';

            targetEl.removeClass('hidden');
            targetEl.find('a').attr('href', banner.url)
              .attr('data-track', dataTrack)
              .attr('data-event', options.viewEvent)
              .attr('data-properties', JSON.stringify(trackProperties))
              .append(customAnalyticsContent);
            targetEl.find('div').css('background-image', `url('${banner.imageUrl}')`);
          } else {
            targetEl.html('').append(renderSingle(banner, options));
          }
        }
      } else if (adsenseEnabled && item.adsenseCode && !targetEl.data('bg')) {
        targetEl.parent().append(item.adsenseCode);
        targetEl.remove();
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    });

    if (analyticsEnabled) initTrackViews();
  };

  const fetchBanners = () => {
    const slugList = [];

    bannerPositions.forEach(item => {
      slugList.push(item.dataset.slug);
    });

    // FETCH FROM API
    $.ajax({
      url: `${apiUrl}/places?include=postings.banner&filter[slug]=${slugList.join(',')}&locale=${window.locale}`,
      method: 'GET',
      success: response => {
        handleBanners(response, isReact);
      },
      error: () => {
        console.log('Can\'t fetch data');
      }
    });
  };

  if (bannerPositions.length > 0) fetchBanners();
};
