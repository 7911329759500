import 'select2/dist/css/select2.css';
import 'select2/dist/js/select2.full';

export const SelectForm = () => {
  const removeDuplicateOfSelect2 = el => {
    if (el.data('select2') === undefined && el.next().hasClass('select2-container')) el.next().remove();
  };

  const selectSingle = () => {
    $('.js-select').each(function () {
      const el = $(this);
      const placeholder = el.data('placeholder');
      const dropdownClass = el.data('dropdown-class');

      removeDuplicateOfSelect2(el);

      el.select2({
        placeholder: placeholder || '',
        dropdownCssClass: dropdownClass || ''
      });
    });
  };

  const selectMulti = () => {
    $('.js-multi-select').each(function () {
      const el = $(this);
      removeDuplicateOfSelect2(el);

      el.select2({
        theme: 'multi',
        closeOnSelect: false
      });

      // hack for not open select when removing item from multiselect
      $('select').on('select2:unselect', function (evt) {
        if (!evt.params.originalEvent) return;
        evt.params.originalEvent.stopPropagation();
      });
    });
  };

  const selectExampleTags = () => {
    $('.js-example-tags').select2({
      tags: true,
      tokenSeparators: ['+']
    }).on('select2:open', function () {
      $('.select2-container--open .select2-dropdown--below').css('display', 'none');
    });
  };

  const selectAsync = () => {
    $('.js-select-async').each(function () {
      const el = $(this);
      const token = document.querySelector('meta[name=\'csrf-token\']') ? document.querySelector('meta[name=\'csrf-token\']').getAttribute('content') : '';

      removeDuplicateOfSelect2(el);

      const $select2 = el.select2({
        minimumInputLength: 3,
        ajax: {
          delay: 250,
          url: el.data('url'),
          dataType: 'json',
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-TOKEN': token
          },
          data: params => el.data('params') + params.term,
          processResults: ({ data }) => ({
            results: data.map(({ attributes, id }) => ({ id, text: attributes.name }))
          })
        }
      });

      $select2.data('select2').$dropdown.addClass(`select-async ${el.data('classes') || ''}`);
    });
  };

  selectSingle();
  selectMulti();
  selectExampleTags();
  selectAsync();

  window.selectSingle = () => selectSingle();
  window.selectMulti = () => selectMulti();
  window.selectExampleTags = () => selectExampleTags();
  window.selectAsync = () => selectAsync();
};
