export const ToggleClass = () => {
  const toggleItems = document.querySelectorAll('.js-toggle-item');

  if (toggleItems.length) {
    toggleItems.forEach((item) => {
      const toggleBtn = item.querySelectorAll(`.${item.dataset.toggleBtnTarget}`);

      if (toggleBtn.length) {
        toggleBtn.forEach((e) => {
          e.addEventListener('click', () => {
            item.classList.toggle(item.dataset.toggleClass);
            if (item.dataset.noScroll) document.querySelector('body').classList.toggle('no-scroll');
          });
        });
      }
    });
  }
};
