import { headerHeight } from '../constants';

export const BlockSticky = () => {
  const stickyBlock = $('.js-block-sticky');

  const handleSticky = () => {
    const stickyWrapper = stickyBlock.parent();
    const windowPosition = $(window).scrollTop();

    if (windowPosition >= (stickyWrapper.offset().top) - headerHeight) {
      stickyBlock.addClass('sticky');
    } else {
      stickyBlock.removeClass('sticky');
    }
  };

  if (stickyBlock.length) {
    $(window).on('load scroll', function () {
      handleSticky();
    });
  }
};
