export const Filter = () => {
  const filter = $('.month-filter');
  const active = $('.month-active-filter');
  const item = filter.find('.item');
  const $magicLine = $('.line');

  function changePostion(element) {
    if (filter.length > 0) {
      $magicLine
        .width(element.width())
        .css('left', element.position().left);
    }
  }

  $(window).on('load turbolinks:load', function () {
    changePostion(active);
  });

  item.on('click', function () {
    item.removeClass('month-active-filter');
    $(this).addClass('month-active-filter');
    changePostion($(this));
  });
};
