import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { ScrollTo } from './ScrollTo';

export const FileUpload = () => {
  const input = document.querySelector('.js-cropper-input');

  if (input) {
    const avatar = document.querySelector('.js-cropper-result img');
    const image = document.querySelector('.js-cropper-source');
    const cropBtn = document.querySelector('.js-cropper-crop-btn');
    const cropDataX = document.querySelector('.js-cropper-data-x');
    const cropDataY = document.querySelector('.js-cropper-data-y');
    const cropDataH = document.querySelector('.js-cropper-data-h');
    const cropDataW = document.querySelector('.js-cropper-data-w');
    const maxFileSize = 1000000;
    const avatarResult = $('.js-avatar-result');
    const avatarError = $('.js-avatar-error');
    const submitBtn = $('.js-profile-submit');
    const modal = $(image).closest('.js-popup');

    let cropper;

    input.addEventListener('change', e => {
      const { files } = e.target;
      const done = function (url) {
        image.src = url;
        modal.addClass('open').trigger('classAdded');
      };

      if (files && files.length > 0) {
        const file = files[0];

        if (file.size < maxFileSize) {
          const reader = new FileReader();
          reader.onload = () => {
            done(reader.result);
          };
          reader.readAsDataURL(file);

          avatarResult.removeClass('hidden');
          avatarError.addClass('hidden');
          submitBtn.removeClass('disabled');
        } else {
          avatarResult.addClass('hidden');
          avatarError.removeClass('hidden');
          submitBtn.addClass('disabled');
        }
      }
    });

    modal.on('classAdded', function () {
      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 3,
        minCropBoxWidth: 100,
        minCropBoxHeight: 100
      });
    }).on('classRemoved', function () {
      cropper.destroy();
      cropper = null;
    });

    cropBtn.addEventListener('click', () => {
      let canvas;

      if (cropper) {
        const cropperData = cropper.getData();
        canvas = cropper.getCroppedCanvas();
        avatar.src = canvas.toDataURL();
        cropDataX.value = cropperData.x;
        cropDataY.value = cropperData.y;
        cropDataH.value = cropperData.height;
        cropDataW.value = cropperData.width;
      }

      modal.removeClass('open').trigger('classRemoved');
    });

    submitBtn.on('click submit', e => {
      e.preventDefault();

      if (submitBtn.hasClass('disabled')) {
        ScrollTo(avatarResult);
      } else {
        setTimeout(() => submitBtn.closest('form').submit(), 100);
      }
    });
  }
};
