import validateFields from './validation';

const CONTAINER = '.js-step-container';
const BUTTON_NEXT_STEP = '.js-btn-next-step';
const BUTTON_PREV_STEP = '.js-btn-prev-step';
const BUTTON_FORM_SUBMIT = '.js-form-submit';
const DATA_STEP = 'data-step';
const DATA_VALDIATE_STEP = 'data-validate-step';

const recaptchaEnabled = window.gon && window.gon.global.recaptcha?.recaptchaEnabled;
const didomiEnabled = window.gon && window.gon.global.recaptcha?.didomiEnabled;

const currentStep = btn => $(btn).closest(`[${DATA_STEP}]`);

const validation = (btn, callback) => {
  const $currentStep = currentStep(btn);
  validateFields($currentStep.attr(DATA_VALDIATE_STEP) && !$(btn).is(BUTTON_PREV_STEP) ? $currentStep.find('[data-rule]') : $(), callback);
};

const goToStep = (btn, num) => {
  validation(btn, () => {
    $(btn)
      .closest(CONTAINER)
      .find(`[${DATA_STEP}="${+currentStep(btn).attr(DATA_STEP) + num}"]:first`)
      .removeClass('hidden')
      .siblings(`[${DATA_STEP}]`)
      .addClass('hidden');
  });

  return false;
};

const submitForm = e => {
  e.stopPropagation();

  if (currentStep(e.target).attr(DATA_VALDIATE_STEP) === 'true') {
    e.preventDefault();

    validation(e.target, () => {
      // eslint-disable-next-line no-undef
      if ((recaptchaEnabled && didomiEnabled && window.didomiEnabled && Didomi.getUserStatusForVendor(window.didomiCustomVendorIds.recaptcha)) || (recaptchaEnabled && !didomiEnabled) ) window.grecaptcha.execute();
      else e.target.form.submit();
    });
  }
};

export const StepScreen = () => {
  $(CONTAINER).each((idx, el) => {
    $(el).find(`${BUTTON_NEXT_STEP}:first`).on('click', ({ target }) => goToStep(target, 1));
    $(el).find(`${BUTTON_PREV_STEP}:first`).on('click', ({ target }) => goToStep(target, -1));
    $(el).find(`${BUTTON_FORM_SUBMIT}:first`)[0].addEventListener('click', submitForm, true);
  });
};
