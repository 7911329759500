export const SearchBarFocus = () => {
  let ctrlDown = false;
  const SearchBarEl = $('#q');

  if ($('.js-focus').length) {
    $(document).keydown(({ keyCode, key, ctrlKey, metaKey }) => {
      if ([17, 91, 99, 118].includes(keyCode) || key === 'Control' || metaKey || ctrlKey) ctrlDown = true;

      if (!ctrlDown) {
        if (keyCode === 27) {
          SearchBarEl.blur();
        } else if (
          metaKey || ctrlKey ||
          ![13, 17, 27, 33, 34, 35, 36, 37, 38, 39, 40, 91, 99, 118].includes(keyCode) ||
          !['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'PageUp', 'PageDown', 'End', 'Home'].includes(key)
        ) {
          SearchBarEl.focus();
        } else SearchBarEl.blur();
      }
    }).keyup(({ keyCode, key, ctrlKey, metaKey }) => {
      if ([17, 91, 99, 118].includes(keyCode) || key === 'Control' || metaKey || ctrlKey) ctrlDown = false;
    });
  }
};
