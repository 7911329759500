const SELECTOR = '.js-countdown';

const totalTime = (endtime) => (
  Math.max(0, Date.parse(endtime.replace(/-/g, '/')) - Date.parse(new Date()))
);

const getTimeRemaining = (deadline) => {
  const total = totalTime(deadline);
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return { total, days, hours, minutes, seconds };
};

const initialize = (idx, el) => {
  const { deadline, reloadPageAfter } = el.dataset;

  if (!deadline) return console.warn('Countdown: Not set attribute [data-deadline]');

  let timeinterval;
  const daysSpan = el.querySelector('.days');
  const hoursSpan = el.querySelector('.hours');
  const minutesSpan = el.querySelector('.minutes');
  const secondsSpan = el.querySelector('.seconds');

  function updateClock() {
    const t = getTimeRemaining(deadline);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = t.hours;
    minutesSpan.innerHTML = t.minutes;
    secondsSpan.innerHTML = t.seconds;

    if (t.total <= 0) {
      clearInterval(timeinterval);
      if (reloadPageAfter === 'true') document.location.reload();
    }
  }

  if (totalTime(deadline) > 0) {
    updateClock();
    timeinterval = setInterval(updateClock, 1000);
  }
};

export const Countdown = () => $(SELECTOR).each(initialize);
