export const FormPlaceholder = () => {
  const form = $('.corporate-hero-form .form__item');
  const input = form.find('input');
  const label = form.find('label');

  label.css('opacity', '0');

  input.keypress(function () {
    $(this).parent().find('label').css('opacity', '1');
  });

  input.focusout(function () {
    const inputLength = $(this).val().length;
    if (inputLength < 1) {
      $(this).parent().find('label').css('opacity', '0');
    }
  });
};
