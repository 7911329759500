const $selectable = $('.js-together-sub-event-selectable');

export const TogetherSubEventSelectable = () => {
  $selectable.each((inx, el) => {
    const $selects = $(el).find('.js-select:not(.sold-out)');
    const $noParticipations = $(el).find('#no_participations');

    if ($selects.length === 0) return;

    $selects.on('select2:select', ({ target }) => {
      const $groupSelects = $(target).closest('[data-together-sub-event-selectable-container]').find('.js-select:not(.sold-out)');

      $(target).siblings('input[name*="[_destroy]"]').val(target.value === '0');
      $groupSelects.each((idx, selectEl) => selectEl !== target && selectEl.value === target.value ? $(selectEl).val('0').trigger('change') : null);

      const $selectedSelects = $groupSelects.filter(function () { return parseInt(this.value, 10) });
      if ($selectedSelects.length === 3) $groupSelects.each((idx, selectEl) => $selectedSelects.is(selectEl) ? null : $(selectEl).val('0').trigger('change'));
    });

    if ($noParticipations.length) {
      $noParticipations.on('change', () => {
        const checked = $noParticipations.is(':checked');
        $selects.prop('disabled', checked).trigger('change');
        $selects.siblings('input[name*="[_destroy]"]').val(checked);
      });
    }
  });
};
