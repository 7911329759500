export const ProgressBar = () => {
  const progressEl = $('.js-progress');

  if (progressEl.length > 0) {
    const input = $('.js-progress-input');
    const img = $('.js-progress-img');
    const avatarInput = $('.js-avatar-upload');
    const hiddenInput = $('.js-hidden-input');
    const progressBg = progressEl.find('.js-progress-bg');
    const progressLine = progressEl.find('.js-progress-line');
    const progressCount = progressEl.find('.js-progress-count');
    const progressTitle = progressEl.find('.js-progress-title');
    const completionTitle = progressEl.data('title');
    const completionColor = progressEl.data('color');
    const dot = progressLine.find('span');
    const partValue = 100 / completionTitle.length;
    let count;

    const calculateCount = () => {
      count = 0;
      input.each(function () {
        if ($(this).val().length) count += +$(this).data('progress-weight');
      });

      if (img.find('img').attr('src').length) count += +img.data('progress-weight');

      if (img.find('img').attr('src').length === 0 && avatarInput.val().length) count += +avatarInput.data('progress-weight');

      const index = Math.ceil(count / partValue) - 1;

      progressBg.css('background-color', completionColor[index]);
      dot.css('margin-left', `${count}%`).css('background-color', completionColor[index]);
      progressTitle.text(completionTitle[index]);

      progressCount.text(`${count}%`);
      hiddenInput.val(count);
    };

    calculateCount();

    input.each(function () {
      $(this).on('input, change', function () {
        calculateCount();
      });
    });

    avatarInput.on('input, change', function () {
      calculateCount();
    });
  }
};
