export const ShowInModal = () => {
  const modalTarget = $('.js-show-in-modal-target');

  if (modalTarget) {
    const showButtons = $('.js-show-in-modal');
    const modal = $('.js-popup');
    const modalContent = modal.find('.js-popup-content');

    const showHideModalButton = () => {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function(){
        modalTarget.each((i, el) => {
          const showButton = $(el).closest('.card').find('.js-show-in-modal');

          if (el.clientHeight >= el.scrollHeight) {
            showButton.addClass('hidden');
          } else {
            showButton.removeClass('hidden');
          }
        });
      }, 200);
    };

    showButtons.on('click', ({ target }) => {
      modalContent.html();
      modal.addClass('open');
      $('body').addClass('open-modal');
      modalContent.html($(target).closest('.card').find('.js-show-in-modal-target').html());
    });

    showHideModalButton();

    $(window).resize(function() {
      showHideModalButton();
    });
  }
};
