export const LoadingBtn = () => {
  const btn = document.querySelectorAll('.js-loading-btn');

  btn.forEach(el => {
    const buttonText = el.innerHTML;

    el.addEventListener('click', () => {
      let secondsLeft = 10;
      const downloadTimer = setInterval(() => {
        if (secondsLeft <= 0) {
          clearInterval(downloadTimer);
          el.innerHTML = buttonText;
          el.classList.remove('disabled');
        } else {
          el.classList.add('disabled');
          el.innerHTML = secondsLeft;
          secondsLeft -= 1;
        }
      }, 1000);
    });
  });
};
