import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

export const OwlCarouselParameters = () => {
  const owlDefault = $('.owl-default');
  const owlResponsive = $('.owl-responsive');
  const owlLanding = $('.owl-landing');
  const owlBenefits = $('.owl-benefits');
  const owlCollection = $('.owl-collection');

  if (owlDefault.length > 0) owlDefault.owlCarousel({
    loop: false,
    items: 1
  });

  if (owlResponsive.length > 0) owlResponsive.owlCarousel({
    margin: 0,
    loop: false,
    items: 1,
    nav: false,
    dots: true,
    autoHeight: true,
    responsive : {
      768 : 
      { 
        items: 2, 
        margin: 20,
        dots: true
      },
      1210 : 
      { 
        items: 3,
        margin: 20, 
        dots: false 
      }
    }
  });

  if (owlLanding.length > 0) owlLanding.owlCarousel({
    margin: 20,
    autoWidth: true
  });

  if (owlBenefits.length > 0) owlBenefits.owlCarousel({
    loop: false,
    items: 1,
    stagePadding: 15
  });

  if (owlCollection.length > 0) owlCollection.owlCarousel({
    loop: false,
    items: 1,
    responsive : {
      768 : { items: 2 },
      1210 : { items: 4 }
    }
  });

  $(window).on('load', function() {
    const owlDark = $('#owl-dark, .js-owl-dark-carousel');

    if (owlDark.length > 0) owlDark.owlCarousel({
      items: 1,
      loop: true,
      nav: true,
      dots: true,
      navText: ['<i class="icon-angle-right"></i>', '<i class="icon-angle-right"></i>']
    });
  });

  setTimeout(function() {
    const owlWithArrow = $('.owl-with-arrow-nav');
    const owlTiles = $('.owl-tiles');

    if (owlWithArrow.length > 0) owlWithArrow.owlCarousel({
      dots: false,
      margin: 30,
      autoHeight: false,
      nav: true,
      items: 1,
      loop: false,
      rewind: true,
      responsive : {
        768 : { items: 2 },
        1210 : { items: 3 }
      }
    });

    if (owlTiles.length > 0) owlTiles.owlCarousel({
      items: 1,
      dots: true,
      mouseDrag: true,
      autoHeight: true
    });
  }, 100);
};
