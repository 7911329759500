/* eslint-disable no-param-reassign */

import moment from 'moment';
import 'moment-duration-format';

export const MomentHelper = () => {
  moment.locale(window.locale);

  const elements = document.querySelectorAll('.js-moment-helper');

  elements.forEach( el => {
    if (el.dataset.formatTime) el.innerHTML = moment.duration(el.dataset.formatTime).format();
    if (el.dataset.formatTimePassed) el.innerHTML = moment(el.dataset.formatTimePassed).fromNow();
  });
};
