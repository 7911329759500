export const EventMobileFilter = () => {
  const filterWrap = $('.event-filter');

  $(document).scroll(function () {
    if (filterWrap.length > 0) {
      if ($(this).scrollTop() >= filterWrap.offset().top - 80) {
        filterWrap.addClass('sticky');
      } else {
        filterWrap.removeClass('sticky');
      }
    }
  });

  $('.js-month-filter-btn').on('click', function () {
    const $this = $(this);

    if ($(window).width() < 1024) {
      $('html, body').animate({
        scrollTop: $this.offset().top - 90
      }, 500);
    }

    $('.js-month-filter-btn').not(this).parent().removeClass('active');
    $this.parent().toggleClass('active');

    if ($this.parent().hasClass('active')) {
      $this.closest('.event-filter').addClass('isOpened');
      $('body').addClass('filter-opened');
    } else {
      $this.closest('.event-filter').removeClass('isOpened');
      $('body').removeClass('filter-opened');
    }
  });

  $('.js-event-filter-shadow').on('click', function () {
    const $this = $(this);

    $this.siblings('.event-filter').removeClass('isOpened');
    $('.js-month-filter-btn').removeClass('active');
    $('.event-filter__item').removeClass('active');
    $('body').removeClass('filter-opened');
  });

  $('.js-loading-trigger a').on('click', () => {
    $('.js-loading').addClass('active');
  });
};
