export const OpenCorporateModal = () => {
  const button = $('.open-corporate-modal');
  const modal = $('.corporate-hero-form');
  const body = $('body');
  const closeModal = $('button.icon-close-o');

  button.on('click', function () {
    modal.addClass('active');
    body.addClass('dark');
  });

  closeModal.on('click', function () {
    modal.removeClass('active');
    body.removeClass('dark');
  });
};
