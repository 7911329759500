import 'jquery-ui/ui/widgets/autocomplete';

export const Autocomplete = () => {
  const elements = $('[data-autocomplete]');

  function build(element) {
    const form = element.is('form') ? element : element.parents('form:first');
    const input = form.find('.js-autocomplete-target');
    const url = element.data('search-url');
    const key = element.data('search-key');
    const noSubmit = element.data('no-submit');
    const appendTo = element.data('append-to') || 'body';
    const options = {
      appendTo,
      source: (request, response) => {
        $.getJSON(url, { q: request.term }, (data) => {
          const wordsArray = key ? data[key] : data;
          const words = wordsArray.map(word => word.name);
          return response(words);
        });
      },
      select: () => {
        if (!noSubmit) setTimeout(() => form.data('remote') ? form.trigger('submit.rails') : form.submit(), 0);
      },
      open: () => {
        $(appendTo).fadeIn(200);
      },
      close: () => {
        $(appendTo).fadeOut(200);
      }
    };

    input.autocomplete(options);
  }

  if (elements.length > 0) {
    elements.each(function () {
      build($(this));
    });
  }
};
