export const Navigation = () => {
  const body = $('body');
  const html = $('html');
  const headerWrap = $('.header__wrap');
  let offsetTop;

  const openNavigation = () => {
    body.toggleClass('closed opened');
    html.toggleClass('opened');
  };

  $('.js-toggle-navigation').on('click', function () {
    openNavigation();

    if (body.hasClass('opened')) {
      $('html, body')
        .off('touchstart touchmove')
        .on('touchstart touchmove', function (e) {
          e.preventDefault();
        });
    }
  });

  $('.js-header-link').on('click', function () {
    openNavigation();
  });

  // add bg to nav when scrolling down
  const stickyNav = () => {
    const offset = headerWrap.data('offset') || 0;
    offsetTop = headerWrap.offset().top;

    if (offsetTop > offset) {
      headerWrap.addClass('sticky');
    } else if (offsetTop <= offset) {
      headerWrap.removeClass('sticky');
    }
  };

  setTimeout(stickyNav(), 100);
  $(window).on('scroll', () => stickyNav());
};
