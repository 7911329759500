export const FlashMessages = () => {
  const flashMessages = $('.flash');

  if (flashMessages.length > 0) {
    flashMessages.each((index, item) => {
      setTimeout(() => {
        $(item).hide(200);
      }, 7000 + index * 1500);

      $(item).find('.js-flash-close').on('click', () => {
        $(item).hide(200);
      });
    });
  }
};
