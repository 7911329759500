import { ScrollTo } from './ScrollTo';

export const ScrollNav = () => {
  const navLinks = $('.js-scroll-nav li a');

  if (navLinks.length) {
    navLinks.each(function () {
      $(this).on('click', ({ target: { dataset } }) => {
        ScrollTo($(dataset.target), dataset.offset);
      });
    });
  }
};
