function playVideoAndHideOverlay(container) {
  const videoid = container.getAttribute('data-video-id');
  const template = `<lite-youtube videoid="${videoid}" posterquality="hqdefault"></lite-youtube>`;
  const style = document.createElement('style');

  style.innerHTML = '#frame::before { display: none; }';

  container.insertAdjacentHTML('beforeend', template);
  container.querySelector('lite-youtube').shadowRoot.appendChild(style);
  container.classList.add('enabled');
  container.classList.remove('disabled');
}

function hideVideoAndShowOverlay(container) {
  const youtube = container.querySelector('lite-youtube');

  if (youtube) youtube.remove();

  container.classList.remove('enabled');
  container.classList.add('disabled');
}

if (window.didomiEnabled) {
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(Didomi => {
    window.setPositiveConsentStatusForYoutube = () => {
      const purposes = Didomi.getVendorById('c:youtube').purposeIds;
      const transaction = Didomi.openTransaction();

      transaction.enableVendor('c:youtube');
      transaction.enablePurposes(...purposes);
      transaction.commit();
    };

    Didomi.getObservableOnUserConsentStatusForVendor('c:youtube')
      .subscribe(consentStatus => {
        document.querySelectorAll('.youtube-container').forEach(container =>
          consentStatus === true
            ? playVideoAndHideOverlay(container)
            : hideVideoAndShowOverlay(container)
        );
      });
  });
} else {
  document.querySelectorAll('.youtube-container').forEach(playVideoAndHideOverlay);
}
