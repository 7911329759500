export const EventsFilter = () => {
  const $filterTrigger = $('.js-filter-trigger');

  $('.js-filter-list li:lt(3)').removeClass('hidden');

  if ($('.js-filter-list li').length > 3) {
    $filterTrigger.removeClass('hidden');
    $filterTrigger.on('click', function () {
      $(this)
        .siblings('.js-filter-list')
        .toggleClass('open');
      $('.show-more').toggleClass('hidden');
      $('.show-less').toggleClass('hidden');
    });
  }
};
