/* eslint no-underscore-dangle: "off", no-multi-assign: "off" */

import jQuery from 'jquery';
import Rails from 'rails-ujs';
import '@justinribeiro/lite-youtube';

import './gdpr';
import { Navigation } from './components/Navigation';
import { SearchBarFocus } from './components/SearchBarFocus';
import { OwlCarouselParameters } from './components/OwlCarouselParameters';
import { SlickCarouselParameters } from './components/SlickCarouselParameters';
import { Dropdown } from './components/Dropdown';
import { Filter } from './components/Filter';
import { FiltersForm } from './components/FiltersForm';
import { EventMobileFilter } from './components/EventMobileFilter';
import { CompanyStickyElement } from './components/CompanyStickyElement';
import { Collapse } from './components/Collapse';
import { FormPlaceholder } from './components/FormPlaceholder';
import { OpenCorporateModal } from './components/OpenCorporateModal';
import { BenefitsTabs } from './components/BenefitsTabs';
import { ShowPassword } from './components/ShowPassword';
import { Modal } from './components/Modal';
import { SharePanel } from './components/SharePanel';
import { FlashMessages } from './components/FlashMessages';
import { SelectForm } from './components/SelectForm';
import { Banners } from './components/Banners';
import { Autocomplete } from './components/Autocomplete';
import { FileUpload } from './components/FileUpload';
import { BlockSticky } from './components/BlockSticky';
import { ScrollNav } from './components/ScrollNav';
import { EventsFilter } from './components/EventsFilter';
import { ShowMore } from './components/ShowMore';
import { ShowInModal } from './components/ShowInModal';
import { ProgressBar } from './components/ProgressBar';
import { Popup } from './components/Popup';
import { ConditionalInput } from './components/ConditionalInput';
import { Survey } from './components/Survey';
import { Pagination } from './components/Pagination';
import { ContentSlider } from './components/ContentSlider';
import { TogetherSubEventSelectable } from './components/TogetherSubEventSelectable';
import { StepScreen } from './components/StepScreen';
import { Countdown } from './components/Countdown';
import { Giveaway } from './components/Giveaway';
import { LoadingBtn } from './components/LoadingBtn';
import { MomentHelper } from './components/MomentHelper';
import { ToggleClass } from './components/ToggleClass';

window.$ = window.jQuery = jQuery;

if (!window._rails_loaded) Rails.start();

Navigation();
OwlCarouselParameters();
SlickCarouselParameters();
Dropdown();
Filter();
FiltersForm();
EventMobileFilter();
CompanyStickyElement();
Collapse();
FormPlaceholder();
OpenCorporateModal();
BenefitsTabs();
ShowPassword();
Modal();
FlashMessages();
SharePanel();
SelectForm();
Banners();
SelectForm();
FileUpload();
Autocomplete();
BlockSticky();
ScrollNav();
SearchBarFocus();
EventsFilter();
ShowMore();
ShowInModal();
ProgressBar();
ConditionalInput();
Popup();
Survey();
Pagination();
ContentSlider();
TogetherSubEventSelectable();
StepScreen();
Countdown();
Giveaway();
LoadingBtn();
MomentHelper();
ToggleClass();
