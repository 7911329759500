export const ConditionalInput = () => {
  const input = $('.js-conditional-input');

  if (input.length) {
    const target = $('.js-conditional-target');
    input.change(() => {
      if (input.val().length) {
        target.val(target.data('value'));
      } else {
        target.val('');
      }
    });
  }
};

window.ConditionalInput = () => ConditionalInput();
