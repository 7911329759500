export const SharePanel = () => {
  $('.js-link-copied').on('click', function () {
    const $el = $(this);
    const value = $el.data('link');
    const $temp = $('<input>');

    $el.closest('.share-panel').addClass('share-panel_copied');
    setTimeout(function () {
      $el.closest('.share-panel').removeClass('share-panel_copied');
    }, 1000);
    $('body').append($temp);
    $temp.val(value).select();
    document.execCommand('copy');
    $temp.remove();
  });

  $('.js-share-popup').on('click', e => {
    e.preventDefault();
    window.open(e.target.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
  });
};
