export const Dropdown = () => {
  const dropdown = $('.dropdown_on-click, .dropdown_on-click_mobile');
  const $window = $(window);

  dropdown.each(function () {
    const $this = $(this);
    const label = $this.find('.dropdown__label');
    label.on('click', () => {
      $this.toggleClass('active');
    });
    $window.on('mouseup touchstart', function (e) {
      if (!$this.has(e.target).length) {
        return $this.removeClass('active');
      }
    });
  });

  // refactor this later delete this part
  const dropdown_legacy = $('.drop-head');

  dropdown_legacy.on('click', function () {
    $(this).parent().toggleClass('active');
  });
};
