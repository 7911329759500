export const ShowPassword = () => {
  const showPasswordButtons = $('.js-show-password');

  if (showPasswordButtons.length > 0) {
    showPasswordButtons.each((index, el) => {
      const button = $(el);
      const input = button.parent().find('input');

      button.on('click', () => {
        if (input[0].type === 'password') {
          input[0].type = 'text';
          button.text(button.data('hide'));
        } else if (input[0].type === 'text') {
          input[0].type = 'password';
          button.text(button.data('show'));
        }
      });
    });
  }
};
