export const ContentSlider = () => {
  const slider = $('.js-content-slider');

  if (slider) {
    const sliderItems = slider.find('.js-slider-item');
    const sliderItemsCount = sliderItems.length;
    const pageBtn = slider.find('.js-slider-page');

    const handleClasses = number => {
      pageBtn.removeClass('active').filter(`[data-tab="${number}"]`).addClass('active');
      sliderItems.removeClass('active').filter(`[data-tab="${number}"]`).addClass('active');
    };

    slider.find('.js-slider-arrow').each((i, btn) => {
      $(btn).on('click', ({ currentTarget }) => {
        const currentTab = sliderItems.filter('.active').data('tab');

        if (currentTarget.dataset.type === 'prev') handleClasses(currentTab > 1 ? currentTab - 1 : sliderItemsCount);
        if (currentTarget.dataset.type === 'next') handleClasses(currentTab < sliderItemsCount ? currentTab + 1 : 1);
      });
    });

    pageBtn.each((index, btn) => {
      $(btn).on('click', ({ currentTarget }) => handleClasses(currentTarget.dataset.tab));
    });
  }
};
