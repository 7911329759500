export const ShowMore = () => {
  const showMoreButton = $('.js-show-more');
  const showMoreTarget = $('.js-show-more-target');

  if (showMoreButton) {
    showMoreButton.on('click', () => {
      showMoreTarget.removeClass('hidden');
      showMoreButton.addClass('hidden');
    });
  }
};
