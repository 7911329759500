/* eslint no-undef: "off" */

import validate from 'validate.js';
import 'packs/i18n/translations';

I18n.locale = window.location.pathname.substring(1, 3);

const t = I18n.t('javascript.account');

// reconfigure standard error messages
validate.validators.presence.options = { message: 'Can\'t be blank' };
// add custom Url validator to allow empty string after editing the field
validate.validators.urlAllowBlank = (value, options, attribute, attributes) => {
  if (validate.isEmpty(value)) {
    return;
  }
  return validate.validators.url(value, options, attribute, attributes);
};

validate.validators.email.PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9._-]+?\.[a-zA-Z]{2,}$/;

// add custom Email validator to allow empty string after editing the field
validate.validators.emailAllowBlank = (value) => {
  if (value === '') {
    return null;
  }
  return validate.single(value, { email: true });
};

const defaultRules = {
  presence:         { presence: { allowEmpty: false } },
  password:         { presence: { allowEmpty: false, message: t.password }, length: { minimum: 6, message: t.password_length } },
  checkbox:         { presence: { message: t.checkbox }, inclusion: { within: [true], message: t.checkbox } },
  select:           { presence: { allowEmpty: false, message: t.select } },
  url:              { presence: { allowEmpty: false }, url: { message: t.url } },
  urlAllowBlank:    { urlAllowBlank: { message: t.urlAllowBlank } },
  email:            { email: { message: t.email_invalid }, presence: { allowEmpty: true, message: t.email } },
  emailAllowBlank:  { emailAllowBlank: { message: t.email } },
  // TODO think about more specific pattern
  phone:            { format: { pattern: /[\d-,+,(,), ]+/, message: t.phone } },
  phoneAllowBlank:  { format: { pattern: /([\d-,+,(,), ]+)?/, message: t.phone } }
};

const Validate = (data: {}, rules: {}) => {
  const validationRules = {};
  const options = {
    fullMessages: false
  };

  Object.keys(rules).forEach(key => {
    const value = rules[key];
    validationRules[key] = defaultRules[value] ? defaultRules[value] : value;
  });

  const errors = validate(data, validationRules, options);

  const result = errors && Object.keys(errors).map(key => {
    errors[key] = errors[key].toString();
    return true;
  });

  return { isValid: !result, errors };
};

const validateFields = (fields, onValidationSuccess) => {
  const data = {};
  const rules = {};

  fields.each(function() {
    const el = $(this);
    const name = el.attr('name');

    data[name] = el.attr('type') === 'checkbox' ? el.is(':checked') : el.val();
    rules[name] = el.data('rule');
  });

  const { isValid, errors } = Validate(data, rules);

  if (isValid) {
    $('.form__item').removeClass('error').find('.form__error').text('').addClass('hidden');
    onValidationSuccess();
  } else {
    fields.each(function() {
      const el = $(this);
      const error = errors[el.attr('name')];

      if (error) {
        el.closest('.form__item').addClass('error').find('.form__error').text(error).removeClass('hidden');
      } else {
        el.closest('.form__item').removeClass('error').find('.form__error').text('').addClass('hidden');
      }
    });
  }
};

export default validateFields;
