function addReCaptchaScript() {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://www.google.com/recaptcha/api.js';
  head.appendChild(script);
}

function showNoRecaptchaMessage() {
  const notice = document.getElementById('recaptcha_notice');
  if (notice) notice.classList.add('recaptcha-notice__visible');
}

function hideNoRecaptchaMessage() {
  const notice = document.getElementById('recaptcha_notice');
  if (notice) notice.classList.remove('recaptcha-notice__visible');
}

if (window.didomiEnabled && ('didomiCustomVendorIds' in window) && window.didomiCustomVendorIds.recaptcha) {
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(Didomi => {
    window.setPositiveConsentStatusForRecaptcha = () => {
      const purposes = Didomi.getVendorById(window.didomiCustomVendorIds.recaptcha).purposeIds;
      const transaction = Didomi.openTransaction();

      transaction.enablePurposes(...purposes);
      transaction.enableVendor(window.didomiCustomVendorIds.recaptcha);
      transaction.commit();
    };

    if (Didomi.isConsentRequired()) {
      Didomi.getObservableOnUserConsentStatusForVendor(window.didomiCustomVendorIds.recaptcha)
        .subscribe(consentStatus => {
          if (consentStatus) {
            addReCaptchaScript();
            hideNoRecaptchaMessage();
          } else {
            showNoRecaptchaMessage();
          }
        });
    } else {
      addReCaptchaScript();
    }
  });
} else {
  addReCaptchaScript();
}

