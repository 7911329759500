/* eslint no-console: "off" */

export const Survey = () => {
  const survey = $('.js-survey');

  if (survey.length > 0) {
    const apiUrl = window.gon && window.gon.global.survey.apiUrl;
    const appId = window.gon && window.gon.global.survey.appId;
    const headers = {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    };

    const getCookie = name => {
      const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"  // eslint-disable-line
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    };

    const showSurvey = data => {
      const fetchedSurveyIds = data.map(({ id }) => id );
      const cookieSurveyIds = getCookie('surveyIds') ? getCookie('surveyIds').split(',') : [];
      const currentSurveys = fetchedSurveyIds.filter(id => !cookieSurveyIds.includes(id) );

      if (currentSurveys.length > 0) {
        const currentSurveyId = currentSurveys[Math.floor(Math.random() * currentSurveys.length)];

        survey.data('survey-id', currentSurveyId).closest('.js-popup').addClass('open');
        document.cookie = `surveyIds=${[ cookieSurveyIds, currentSurveyId ].flat()}; max-age=15768000`;
      }
    };

    const getSurveys = () => {
      $.ajax({
        url: `${apiUrl}/survey_apps/${appId}/surveys`,
        method: 'GET',
        headers,
        success: ({ data }) => {
          document.cookie = 'surveyFetched=true; max-age=604800';

          if (data.length > 0) showSurvey(data);
        },
        error: errors => console.log('=====', errors)
      });
    };

    const handleVote = el => {
      const surveyId = survey.data('survey-id');

      $.ajax({
        url: `${apiUrl}/votes`,
        method: 'POST',
        headers,
        data: JSON.stringify({
          data: {
            type: 'votes',
            attributes: { score: el.val() },
            relationships: {
              survey: { data: { type: 'surveys', id: surveyId } }
            }
          }
        }),
        success: () => {
          survey.find('.js-survey-body').removeClass('open');
          survey.find('.js-survey-success').addClass('open');
          setTimeout(() => survey.closest('.js-popup').removeClass('open'), 5000);
        },
        error: () => {
          survey.find('.js-survey-body').removeClass('open');
          survey.find('.js-survey-error').addClass('open');
          document.cookie = `surveyIds=${getCookie('surveyIds').split(',').filter(e => e !== surveyId)}; max-age=15768000`;
          setTimeout(() => survey.closest('.js-popup').removeClass('open'), 5000);
        }
      });
    };

    if (!getCookie('surveyFetched')) {
      getSurveys();
    }

    $('.js-survey-btn').each(function() {
      $(this).on('click', () => {
        handleVote($(this));
      });
    });
  }
};
