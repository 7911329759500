import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

export const SlickCarouselParameters = () => {

  $('.js-slick').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: '.js-slick-nav',
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          dotsClass: 'slider__dots'
        }
      }
    ]
  });

  $('.js-slick-nav').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    focusOnSelect: true,
    arrows: false,
    asNavFor: '.js-slick',
    infinite: false,
    dotsClass: 'slider__dots',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  $('.js-slick-gallery').each(function() {
    const $this = $(this);
    const gallery = $this.find('.js-slick-main');
    const thumbs = $this.find('.js-slick-thumbs');
    const arrows = $this.data('arrows') === true;

    gallery.slick({
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: arrows,
      asNavFor: thumbs
    });

    thumbs.slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: gallery,
      dots: true,
      dotsClass: 'slider__dots',
      arrows: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            arrows: false
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    });
  })
};
