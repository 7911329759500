export const Popup = () => {
  const popup = $('.js-popup');
  const body = $('body');

  if (popup.length > 0) {
    $('.js-popup-close').each(function () {
      $(this).on('click', () => {
        popup.removeClass('open');
        body.removeClass('open-modal');
      });
    });
  }
};
