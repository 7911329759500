export const Modal = () => {
  const buttons = document.querySelectorAll('[data-modal-trigger]');

  function modalEvent(button) {
    button.addEventListener('click', () => {
      const trigger = button.getAttribute('data-modal-trigger');
      const modal = document.querySelector(`[data-modal-name=${trigger}]`);
      const contentWrapper = modal.querySelector('.modal__block');
      const close = modal.querySelector('.close');
      const body = document.querySelector('body');

      close.addEventListener('click', () => {
        modal.classList.remove('open');
        body.classList.remove('open-modal');
      });
      modal.addEventListener('click', () => modal.classList.remove('open'));
      contentWrapper.addEventListener('click', e => e.stopPropagation());

      modal.classList.toggle('open');
      body.classList.toggle('open-modal');
    });
  }

  buttons.forEach(button => {
    modalEvent(button);
  });
};
