export const CompanyStickyElement = () => {
  const menuWrap = $('.mobile-menu-wrap');

  $(document).scroll(function () {
    if (menuWrap.length > 0) {
      if ($(this).scrollTop() >= menuWrap.offset().top - 80) {
        menuWrap.addClass('active');
      } else {
        menuWrap.removeClass('active');
      }
    }
  });
};
